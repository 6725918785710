
import projectConstants from '~/store/project/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import baseConstants from '~/store/base/-constants'

export default {
  name: 'TemplateProject',
  metaInfo() {
    return {
      title: this.$store.state.base.meta.generalConfig.baseTitle,
      titleTemplate: `${this.$store.state.base.meta.generalConfig.baseTitle} - ${this.meta.title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.meta.description
        }
      ]
    }
  },
  data() {
    return {
      sections: []
    }
  },
  head() {
    const { generalConfig } = this.$store.state.base.meta;
    const { cdnBase } = generalConfig;
    const meta = [
      { vmid: 'title', name: 'title', content: this.meta.title },
      { vmid: 'description', name: 'description', content: this.meta.description },
      { vmid: 'og:description', name: 'og:description', property: 'og:description', content: this.meta.description },
      { vmid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { vmid: 'twitter:description', name: 'twitter:description', content: this.meta.description },
    ]

    if (this.meta.image) {
      meta.push(
        { vmid: 'image', name: 'image', content: `${cdnBase}/${this.meta.image}` },
        { vmid: 'og:image', name: 'og:image', property: 'og:image', content: `${cdnBase}/${this.meta.image}` },
        { vmid: 'twitter:image', name: 'twitter:image', content: `${cdnBase}/${this.meta.image}` }
      )
    }
    const configMeta = {...this.meta};
    delete configMeta['title'];
    delete configMeta['description'];
    delete configMeta['image'];
    Object.keys(configMeta).forEach(key => {
      meta.push({ vmid: key, name: key, content: configMeta[key] })
    });

    return {
      title: generalConfig.baseTitle,
      titleTemplate: `${generalConfig.baseTitle} - ${this.meta.title}`,
      meta,
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: generalConfig.favicon
        }
      ]
    }
  },
  computed: {
    meta() {
      return {
        ...this.$store.state.base.meta.projectConfig.meta,
        baseTitle: this.$store.state.base.meta.generalConfig.baseTitle
      }
    },
    sectionsData() {
      return this.$store.state.base.meta.projectConfig.sections
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    buildingRef() {
      const found = this.$refs.sections?.find(function (s) {
        return s.$refs.buildingSection
      })
      if (!found) return null
      return found.$refs.buildingSection.$refs.container3d.$refs.building
    },
    portfolioPageDisabled() {
      return this.$store.state.base.meta.generalConfig.portfolioPageDisabled
    }
  },
  watch: {
    activeProject: function (value, old) {
      if (value !== old) {
        this.portfolioPageDisabled && this.buildingRef.initializeBuilding3D()
      }
    },
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections()
        if (this.$store.state.base.documentIsReady) {
          this.$store.dispatch(
            baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
            false
          )
        }
      }
    }
  },
  created() {
    this.sortSections()
    this.$store.dispatch(
      projectConstants.withNamespace(projectConstants.action.LOAD_LANDLORD_DETAILS)
    )
  },
  mounted() {
    window.addEventListener('keydown', this.disableArrowScroll, false);
    this.initializeBuilding3D();
    this.$store.dispatch(
      requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_STATE),
      null
    )
    this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CLOSE_SIDEBAR_STATE))
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.disableArrowScroll)
  },
  methods: {
    initializeBuilding3D() {
      if (!this.activeProject || !this.buildings.length > 0) {
        setTimeout(() => {
          this.initializeBuilding3D();
        }, 100);
        return;
      }
      this.buildingRef.initializeBuilding3D()
    },
    sortSections() {
      this.sections = [...this.sectionsData].sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1
        }
        if (a.order_no > b.order_no) {
          return 1
        }
        return 0
      })
    },
    disableArrowScroll(e) {
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
        e.preventDefault()
      }
    },
    removePageLoader() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_PAGE_LOADING_STATE),
        false
      )
    }
  }
}
